import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import styles from '../../components/BlogTeaser/BlogTeaser.module.scss';

const AktuellPage = () => (
  <Layout headerWhite>
    <SEO title="Aktuelles" />
    <Section>
      <Container>
        <Headline subtitle="Praxis für Orthopädie">Aktuelles</Headline>
        <Row>
            <Col xs="12" md="10">
              <div className={styles.blogTeaser}>
                {/* <div>
                  <Img fluid={document.node.image.childImageSharp.fluid} />
                </div> */}
                <div className={styles.content}>
                  <h2>
                    <Link to={`/aktuelles/vfl`}>
                      Anzeige im VFL Magazin
                    </Link>
                  </h2>
            
                  
            
                  <Link className={styles.readMore} to={`/aktuelles/vfl`}>
                    Zur Ansicht.
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </Col>
        </Row>
        <Row>
            <Col xs="12" md="10">
              <div className={styles.blogTeaser}>
                {/* <div>
                  <Img fluid={document.node.image.childImageSharp.fluid} />
                </div> */}
                <div className={styles.content}>
                  <h2>
                    <Link to={`/aktuelles/corona`}>
                      Coronavirus
                    </Link>
                  </h2>
            
                  <p>Aufgrund des derzeitigen Infektionsgeschehens halten wir uns an die von der Bundesregierung und des RKI geforderten Hygiene-Maßnahmen und Regeln, um Ihnen einen bestmöglichen Schutz zu bieten. Dieses umfasst im Einzelnen ...</p>
            
                  <Link className={styles.readMore} to={`/aktuelles/corona`}>
                    Mehr lesen.
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </Col>
        </Row>
        <Row>
            <Col xs="12" md="10">
              <div className={styles.blogTeaser}>
                {/* <div>
                  <Img fluid={document.node.image.childImageSharp.fluid} />
                </div> */}
                <div className={styles.content}>
                  <h2>
                    <Link to={`/aktuelles/eroeffnung`}>
                      Eröffnung
                    </Link>
                  </h2>
            
                  <p>Am 1.1.2021 feiern wir Eröffnung! Mitten in Bochum am Dr.-Ruer-Platz Nr. 1, unter uns ein Parkhaus mit über 300 Stellplätzen mit barrierefreiem Zugang zur Praxis, einem Wartebereich mit Minibar, kostenlosen Zugang zum WLAN Netz und HD Flachbild TV ...</p>
            
                  <Link className={styles.readMore} to={`/aktuelles/eroeffnung`}>
                    Mehr lesen.
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AktuellPage;
